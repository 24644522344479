.full-text-search-container {
  position: relative;
}

.full-text-search-icon {
  position: absolute;
  right: 6px;
  top: 8px;
  color: #666;
  a {
    color: #666;
  }
}
