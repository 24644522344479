@import 'src/styles/utils/colors.scss';

.subscription-grid {
  .price-label {
    font-size: 30px;
    font-weight: bold;
    color: $openBrokerBlue;

    .per-month, .annual-bill {
      display: block;
      font-size: 20px;
      font-weight: normal;
      color: $default-color;
    }

    .annual-bill {
      font-size: 12px;
      margin-top: 20px;
    }
  }

  .subscription-button {
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
