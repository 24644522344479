.title-action-header {
  display: flex;
  align-items: center;
  button {
    margin: 0;
  }
  margin-bottom: 20px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .main {
    flex-grow: 1;
    margin: 0;
  }

  .actions {
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
